import {
  Card,
  CardContent,
  Container,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import SoundSegLogo from '../components/SoundSegLogo';
import SubmitAudioForm from '../components/SubmitAudioForm';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import React from 'react';

const Home = () => {
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const storage = getStorage();
  const [downloadUrl, setDownloadUrl] = React.useState<string | null>(null);

  React.useEffect(() => {
    const fetchDownloadUrl = async () => {
      try {
        const storageRef = ref(storage, '/assets/demo_audio/audio.mp3');
        const url = await getDownloadURL(storageRef);
        setDownloadUrl(url);
      } catch (error) {
        console.error("Error fetching download URL:", error);
      }
    };

    fetchDownloadUrl();
  }, []);

  const handleDownload = async () => {
    try {
      const response = await fetch(downloadUrl!);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = 'sample_audio.mp3';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  return (
    <Container sx={{ paddingTop: '3rem', paddingBottom: '3rem' }}>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item>
          <SoundSegLogo
            logoWidth={mdDown ? '5rem' : '10rem'}
            fontSize={mdDown ? '2.5rem' : '4rem'}
          />
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography>
                Welcome to SoundSeg! This is a tool for segmenting and analyzing
                sound data. If you enter a URL for a song, the tool will
                convert the video into an audio file and separate its components.
                Our technique leverages Convolutional Neural Networks and spectral 
                signal processing for effective sound segmentation. For more details,
                visit our{' '}
                <a
                  href="https://github.com/mbrotos/SoundSeg"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GitHub repository
                </a>
                . Additionally, you can explore different spectral normalization techniques in the <a href="https://arxiv.org/abs/2405.20059" target="_blank" rel="noopener noreferrer">arXiv paper</a>.
                {downloadUrl && (
                  <> You can also upload this <a href="#" style={{ color: 'blue' }} onClick={handleDownload}>sample audio file</a> to test the tool.</>
                )}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <SubmitAudioForm />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Home;
